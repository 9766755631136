import { createSlice } from '@reduxjs/toolkit'

// Define a type for the slice state
interface TestState {
  value: number;
  text: string;
}

// Define the initial state using that type
const initialState: TestState = {
  value: 24,
  text: "Hello world"
}

const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    increment: state => {
      state.value += 1;
    },
    decrement: state => {
      state.value -= 1;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
    setText: (state, action) => {
      state.text = action.payload
    }
  },
});

// destructure actions and reducer from the slice (or you can access as counterSlice.actions)
const { actions, reducer } = counterSlice;
// export individual action creator functions
export const { increment, decrement, incrementByAmount } = actions;
// often the reducer is a default export, but that doesn't matter
export default reducer;