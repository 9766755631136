import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './State/store';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navigation from './Navigation';
import ShareScreen from './Pages/ShareScreen';
import ShareScreenNoApp from './Pages/ShareScreenNoApp';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <Navigation />
        <Routes>
          <Route path="/" element={<App message='Hello' />} />
          <Route path="share" element={<ShareScreen />} />
          <Route path="share-install-app" element={<ShareScreenNoApp />} />
        </Routes>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
