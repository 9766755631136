import ClickTestComponent from './Components/ClickTestComponent';
import logo from './logo.svg';
import React, { useEffect } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import './App.css';
import { useScreenWidth } from './Hooks/useScreenWidth';
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from './State/store'
import { incrementByAmount } from './State/TestSlice'
import { useNavigate } from "react-router-dom";

type Props = {
  message: string;
};

const App = ({ message }: Props) => {
  // Hooks
  const width = useScreenWidth()
  const [searchParams] = useSearchParams();
  const pageQuery = searchParams.get('page');
  const shareID = searchParams.get('id');
  const navigate = useNavigate();
  // Redux
  const count = useSelector((state: RootState) => state.counter.value)
  const dispatch = useDispatch();

  useEffect(() => {
    // Redirect to pages
    // http://localhost:3000/?page=share&id=0f9xiwiG4uFNTQI21aTW2sAfQ
    if (pageQuery === 'share') {
      navigate('/share?id=' + shareID);
    }
    if (pageQuery === 'share-install-app') {
      navigate('/share-install-app?id=' + shareID);
    }
    document.title = `You clicked ${count} times`;
  });

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit page {width} <code>src/App.tsx</code> and save to count: {count}.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React with query {pageQuery}
        </a>
        <button aria-label="Increment value" onClick={() => dispatch(incrementByAmount(10))}> Increment</button>
        <NavLink className="nav-link" to="share">Sharing</NavLink>
        <ClickTestComponent message={'Hola'} />
      </header>
    </div>
  );
};

export default App;