import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

type Props = {
  message?: string;
};

const ShareScreenNoApp = ({ message = '' }: Props) => {
  const [count, setCount] = useState(0);
  const [searchParams] = useSearchParams();
  const shareID = searchParams.get('id');
  return (
    <div>
      <p>You clicked {count} times for {message}</p>
      <button onClick={() => setCount(count + 1)}>
        Click me
      </button>
      <p>Install GoOutSafe App, then click on the sharing link to open in app and reveal its content.</p>
      <a href={'https://www.icloud.com/share/' + shareID}>Accept Invitation</a>
    </div>
  );
};

export default ShareScreenNoApp;