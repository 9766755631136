import React, { useState } from 'react';

type Props = {
  message?: string;
};

const ClickTestComponent = ({ message = '' }: Props) => {
  const [count, setCount] = useState(0);
  return (
    <div>
      <p>You clicked {count} times for {message}</p>
      <button onClick={() => setCount(count + 1)}>
        Click me
      </button>
    </div>
  );
};

export default ClickTestComponent;