import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

type Props = {
  message?: string;
};

const ShareScreen = ({ message = '' }: Props) => {
  const [count, setCount] = useState(0);
  const [searchParams] = useSearchParams();
  const shareID = searchParams.get('id');
  return (
    <div>
      <p>You clicked {count} times for {message}</p>
      <button onClick={() => setCount(count + 1)}>
        Click me
      </button>
      <p>Click to open in GoOutSafe App</p>
      <a href={'https://www.icloud.com/share/' + shareID}>Accept Invitation</a>
    </div>
  );
};

export default ShareScreen;